<template>
	<div class="containermain">
		<div class="containerimg">
			<img style="width: 100%;" src="../../assets/newimgtu.png" alt="">
		</div>
		<div class="mianban" :style="{height:Content ==''?'50vh':''}">
			<div class="mbxdh">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/index/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/index/newsComp' }">新闻动态</el-breadcrumb-item>
					<el-breadcrumb-item>新闻详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="xqtitle">
				{{title}}
				<p>{{createTime}}</p>
			</div>
			<div class="eclitxt"  v-html="Content">
				{{Content}}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DetailsComp",
	data(){
		return {
			createTime:'',
			title:'',
			Content:'',
			id:'',
			id1:''
		}
	},
	mounted() {
		this.details();
	},
	methods:{
		// 请求新闻详情信息
		async details() {
			var that = this;
			this.id1= localStorage.getItem('id1'); // 大图
			this.id= localStorage.getItem('id'); //小图
			if(that.$route.query.type=="bigImage"){
				const {data:res} = await this.$http.get('gw/news/getWzNewsDetail?id='+this.id1)
				that.createTime=res.data.createTime;
				that.title=res.data.title;
				var result = res.data;
				that.Content=result.Content;
			}else{
				const {data:res} = await this.$http.get('gw/news/getWzNewsDetail?id='+this.id)
				that.createTime=res.data.createTime;
				that.title=res.data.title;
				var result = res.data;
				that.Content=result.Content;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-breadcrumb__inner.is-link{
	color: #4b4f54;
}
.introduction1>div {
	display: inline-block;
	width: 300px;
	/* margin-top: 50PX;
	margin-left: 150px; */
}
.introduction1>div>img{
	padding-top: 50px;
	padding-left: 95px;
}
.introduction1>div>h3{
	color: #717171;
	text-align: center;
	margin-top: 8px ;
	
}
.introduction1>div>p{
	color: #717171;
	text-align: center;
	width: 250px;
	margin-left: 20px;
}
.xm>h2{
	margin-left: 19%;
}
.el-dropdown + .el-dropdown {
	margin-left: 15px;
	width: 500px !important;
}
.cooperation>div {
	display: inline-block;
	margin-right: 1%;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 15%;
	height: 50px;
	border: 1px solid #717171;
	text-align: center;
	overflow: hidden;
	font-size: 13px;
}
.cooperation>div>span {
	width: 188px;
	height: 50px;
	text-align: center;
	margin-top: 50px;
	line-height: 44px;
}
.containermain {
	width: 100%;
	height: 100%;
	/* background-color: #0f91ee; */
}
.containerimg {
	width: 100%;
	height: 26%;
	/* background-color: #fdfdfd; */
}
.mianban {
	width: 63%;
	height: 70%;
	/* background-color: #fff; */
	margin: 0 auto;
}
.mbxdh {
	margin-top: 1%;
}
.xqtitle{
	color: #2b2b2b;
	font-size: 18px;
	font-weight: 800;
	margin-top: 10px;
}
.xqtitle>p {
	color: #717171;
	font-size: 10px;
	font-weight: 200;
}
.eclitxt {
	width: 100%;
	height: 93%;
	/* background-color: aqua; */
	overflow: auto;
}
.el-tabs--card>.el-tabs__header .el-tabs__nav {
	border: none !important;
	z-index: 999;
}
</style>
